import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/user.js";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import Layout from "../views/Layout.vue";
import Changepass from "../views/Changepass.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      // {
      //   path: '/new-booking/:id',
      //   name: 'new-booking',
      //   meta: { requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "instructionPage" */ '../components/newBooking/newBooking.vue'
      //     )
      // },
      {
        path: "/instruction",
        name: "instruction",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "instruction" */ "../views/Instruction.vue"),
      },
      {
        path: "/new-booking",
        name: "new-booking",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "new-booking" */ "../components/newBooking/newBooking.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/allInstructions",
        name: "allInstructions",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "allInstructions" */ "../views/AllInstructions.vue"),
      },
      {
        path: "/summary",
        name: "summary",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "summary" */ "../components/summary/summary.vue"),
      },
      {
        path: "/order-details/:id",
        name: "summary",
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "instructionPage" */ "../components/summary/summary.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: Register,
      },
    ],
  },
  {
    path: "/changepass/*",
    name: "changepass",
    component: Changepass,
  },
  { path: "*", redirect: "/allInstructions" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getIsLoggedIn(store.state)) {
      next();
      return;
    } else {
      next("/");
    }
  } else if (to.path === "/" || to.path === "/register" || to.name === "changepass") {
    if (store.getters.getIsLoggedIn(store.state)) {
      next("/allInstructions");
      return;
    } else {
      next();
    }
  }
});

export default router;
