import { TOAST_TYPE } from "../utils/constants";
export default {
  namespaced: true,
  state: {
    message: "",
    type: "",
  },
  mutations: {
    SET_TOAST(state, payload) {
      state.message = payload.message;
      state.type = payload.type;
    },
  },
  actions: {
    setToast({ commit }, payload) {
      //'Error' and 'Success' will replaced by default messages from localStorage on different languges
      const messagesList = JSON.parse(localStorage.getItem("messagesList")) || [];
      if (!messagesList?.length) return;
      const message = messagesList[payload.message] || (payload.type === TOAST_TYPE.ERROR ? "Error" : "Success");
      commit("SET_TOAST", { message: message, type: payload.type });
      this._vm.$toast.open(this.state.toast);
    },
  },
};
