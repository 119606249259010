export function instructionFormBuilder(instruction) {
  return {
    PleaseUseTheFollowingDataTo: instruction.documentTypeId,
    shipper: instruction.shipper,
    carrier: instruction.carrier,
    shipmentNumber: instruction.shipmentNumber,
    consignee: instruction.consignee,
    consigneeOrder: instruction.consigneeOrder.toString(),
    notifyAddress: instruction.notifyAddress,
    vessel: instruction.vessel,
    voyage: instruction.voyageNumber,
    portsOfLoading: JSON.stringify(instruction.loadingPort),
    portsOfDischarge: JSON.stringify(instruction.dischargePort),
    B_L_Number: instruction.blNumber,
    shippersExportReference: instruction.exportReference,
    forwardingAgentAddress: instruction.agentAdress,
    consigneesReference: instruction.consigneeReference,
    laceOfReceipt: instruction.receiptPlace,
    placeOfDelivery: instruction.deliveryPlace,
    movementType1: instruction.containerLoad,
    movementType2: instruction.shippingServiceType,
    totalNumberOfContainers: instruction.totalNumberOfContainers,
    totalNumberOfPackages: instruction.totalNumberOfPackages,
    AMS_SCAC_code_ACI_NumberCode: instruction.AMS_SCAC_code_ACI_NumberCode,
    AMS_ACI_selfFiler: instruction.AMS_ACI_selfFiler.toString(),
    taxId: instruction.taxId,
    freightPayableAt: instruction.freightPayableAt,
    invoiceReference: instruction.invoiceReference,
    portChargeOrigin: instruction.portChargeOrigin,
    portChargeDestination: instruction.portChargeDestination,
    destinationHaulageCharges: instruction.destinationHaulageCharges,
    selfFreightAdditional: instruction.selfFreightAdditional,
    setChargeTo: instruction.setChargeTo,
    documentType: instruction.documentType,
    receiveSeaWaybillOfLanding: instruction.receiveSeaWaybillOfLanding,
    ["receiveEMailAddressOfFinalB/L"]: instruction.receiveEMailAddressOfFinalBL,
    remarks: instruction.remarks,
    numberOfFreighted: JSON.stringify(instruction.numberOfFreighted),
    numberOfUnfreighted: JSON.stringify(instruction.numberOfUnfreighted),
    containers: instruction.containers.map((container) => ({
      ...container,
      cargos: container.cargos.map((cargo) => ({
        ...cargo,
        measurement: cargo.measurement?.toString(),
        grossCargoWeight: cargo.grossCargoWeight?.toString(),
      })),
    })),
    departure_date: instruction.departure_date,
  };
}

export function instructionFormResponseBuilder(result) {
  return {
    shipper: result.shipper,
    carrier: result.carrier,
    shipmentNumber: result.shipmentNumber,
    documentTypeId: result.PleaseUseTheFollowingDataTo,
    consignee: result.consignee,
    consigneeOrder: result.consigneeOrder === "true",
    notifyAddress: result.notifyAddress,
    vessel: result.vessel,
    voyageNumber: result.voyage,
    loadingPort: jsonParser(result.portsOfLoading),
    dischargePort: jsonParser(result.portsOfDischarge),
    blNumber: result.B_L_Number,
    exportReference: result.shippersExportReference,
    agentAdress: result.forwardingAgentAddress,
    consigneeReference: result.consigneesReference,
    receiptPlace: result.laceOfReceipt,
    deliveryPlace: result.placeOfDelivery,
    containerLoad: result.movementType1,
    shippingServiceType: result.movementType2,
    totalNumberOfContainers: result.totalNumberOfContainers,
    totalNumberOfPackages: result.totalNumberOfPackages,
    AMS_SCAC_code_ACI_NumberCode: result.AMS_SCAC_code_ACI_NumberCode,
    AMS_ACI_selfFiler: result.AMS_ACI_selfFiler === "true",
    taxId: result.taxId,
    freightPayableAt: result.freightPayableAt,
    invoiceReference: result.invoiceReference,
    portChargeOrigin: result.portChargeOrigin,
    portChargeDestination: result.portChargeDestination,
    destinationHaulageCharges: result.destinationHaulageCharges,
    selfFreightAdditional: result.selfFreightAdditional,
    setChargeTo: result.setChargeTo,
    documentType: result.documentType,
    receiveSeaWaybillOfLanding: result.receiveSeaWaybillOfLanding,
    receiveEMailAddressOfFinalBL: result["receiveEMailAddressOfFinalB/L"],
    remarks: result.remarks,
    numberOfFreighted: jsonParser(result.numberOfFreighted),
    numberOfUnfreighted: jsonParser(result.numberOfUnfreighted),

    containers: result.containers,
    departure_date: new Date().toISOString(),
  };
}
const jsonParser = (strig) => {
  return strig ? JSON.parse(strig) : {};
};
export const INSTRUCTION_EMPTY = {
  documentTypeId: "",
  shipper: "",
  carrier: "",
  shipmentNumber: "",
  consigneeOrder: "",
  consignee: "",
  notifyAddress: [""],
  vessel: "",
  voyageNumber: "",
  loadingPort: "",
  dischargePort: "",
  blNumber: "",
  exportReference: "",
  agentAdress: "",
  consigneeReference: "",
  receiptPlace: "",
  deliveryPlace: "",
  containerLoad: "",
  shippingServiceType: "",
  totalNumberOfContainers: 1,
  totalNumberOfPackages: 1,
  AMS_SCAC_code_ACI_NumberCode: "",
  AMS_ACI_selfFiler: "",
  taxId: "",
  freightPayableAt: "",
  invoiceReference: "",
  setChargeTo: "",
  portChargeOrigin: "",
  portChargeDestination: "",
  destinationHaulageCharges: "",
  selfFreightAdditional: "",
  documentType: "",
  receiveSeaWaybillOfLanding: "",
  receiveEMailAddressOfFinalBL: "",
  remarks: "",
  numberOfFreighted: { originalBsL: "", copies: "" },
  numberOfUnfreighted: { originalBsL: "", copies: "" },
  containers: [
    {
      containerNos: "",
      marksAndNo: "",
      sealNo: [],
      quantity: 1,
      containerType: {
        id: null,
        name: "",
        size: "",
      },
      cargos: [
        {
          numberOfPackages: 1,
          kindOfPackages_UN_PackingCode: "",
          kindOfPackagesPrintOnB_L_as: "",
          descriptionOfGoods: "",
          grossCargoWeight: "",
          unitOfWeight: "",
          measurement: "",
          unitOfCargoesMeasurement: "",
          fak: false,
          hs: {
            id: null,
            section: "",
            hscode: "",
            description: "",
            parent: "",
            level: null,
          },
          codeList: [],
          codeListLoading: false,
        },
      ],
    },
  ],
  departure_date: "",
};

export const instructionFormData = {
  instructionFormRadio: [{ text: "All Prepaid" }, { text: "All Collect" }, { text: "Individually" }],
  setChargeToItems: [
    { text: "Port Charge Origin", selector: "portChargeOrigin" },
    { text: "Self Freight + Additionals", selector: "selfFreightAdditional" },
    { text: "Port Charge Destinations", selector: "portChargeDestination" },
    {
      text: "Destination Haulage Charges",
      selector: "destinationHaulageCharges",
    },
  ],
  followingDataTo: [
    { text: "Create a new B/L Instruction", value: "1" },
    {
      text: "Change a Previously Sent B/L Instruction",
      value: "2",
    },
    { text: "Make Another Part load / Split B/L Instruction", value: "3" },
  ],
  containerLoad: [
    { text: "FCL/FCL", value: "1" },
    { text: "FCI/LCL", value: "2" },
    { text: "LCL/FCI", value: "3" },
    { text: "LCL/LCL", value: "4" },
  ],
  shippingServiceType: [
    { text: "Port/Port", value: "1" },
    { text: "Port/Door", value: "2" },
    { text: "Door/Port", value: "3" },
    { text: "Door/Door", value: "4" },
  ],
  freightPayableAt: [
    { text: "Departure Point", value: "1" },
    { text: "Destination Point", value: "2" },
  ],
  documentType: [
    { text: "Sea Waybill", value: "1" },
    { text: "Multimodal Bill", value: "2" },
    { text: "Master Bill", value: "3" },
    { text: "House Bill", value: "4" },
    { text: "Original Bill", value: "5" },
    { text: "Telex Released Bill", value: "6" },
  ],
  kindOfPackages_UN_PackingCode: [
    { text: "Box | BX", value: "1" },
    { text: "Case | CS", value: "2" },
    { text: "Bar | BR", value: "3" },
    { text: "Dipenser | DN", value: "4" },
    { text: "Flask | FN", value: "5" },
    { text: "Pot | PT", value: "6" },
  ],
  unitOfWeight: [{ text: "KGM" }, { text: "TMT" }, { text: "PG" }, { text: "CWA" }],
  unitOfCargoesMeasurement: [
    { text: "MTQ", value: "1" },
    { text: "LT", value: "2" },
    { text: "DLT", value: "3" },
    { text: "HLT", value: "4" },
  ],
  receiveSeaWaybillOfLandingItems: [
    { text: "Email", value: "1" },
    { text: "Post", value: "2" },
    { text: "Download", value: "3" },
    { text: "Self-Collector", value: "4" },
  ],
  freightedItems: [
    { text: "Number of Freighted", selector: "numberOfFreighted" },
    { text: "Number of Unfreighted", selector: "numberOfUnfreighted" },
  ],
  numberItems: [
    { text: "Original Bs/L", selector: "originalBsL" },
    { text: "Copies", selector: "copies" },
  ],
};
